import React from "react";
import { FaRegMoneyBillAlt } from "@react-icons/all-files/fa/FaRegMoneyBillAlt";
import { AiOutlineAppstoreAdd } from "@react-icons/all-files/ai/AiOutlineAppstoreAdd";
import { AiFillCrown } from "@react-icons/all-files/ai/AiFillCrown";
import { AiOutlineBank } from "@react-icons/all-files/ai/AiOutlineBank";
import { AiOutlineCodeSandbox } from "@react-icons/all-files/ai/AiOutlineCodeSandbox";
import { IoGameControllerOutline } from "@react-icons/all-files/io5/IoGameControllerOutline";
import { AiOutlineReddit } from "@react-icons/all-files/ai/AiOutlineReddit";
import { isDev } from "../../../common/helpers/utils";
import { CategoryCardProps } from "@components/UI-Elements/Cards/CategoryCard";

type Category = {
  slug: string;
} & Omit<CategoryCardProps, 'linkProps'>;

export const categoriesCards: Category[] = [
  ...(isDev
    ? [
        {
          title: "Digitales Gold",
          content:
            "Kryptowährungen die eine Begrenzte Mengen haben und als Wertspeicher genutzt werden können.",
          slug: "digitales-gold",
          color: "bg-yellow-500",
          icon: <AiOutlineBank size="50" className="text-white" />,
        },
      ]
    : []),
  {
    title: "DeFi",
    content:
      "Kryptowährungen, die zum Decentralized Finance Ökosystem gehören.",
    slug: "defi",
    color: "bg-violet-500",
    icon: <AiOutlineBank size="50" className="text-white" />,
  },
  {
    title: "Stablecoins",
    content:
      "Stablecoins sind Kryptowährungen, welche den Wert von einer Fiat-Währung wie dem Euro abbilden.",
    slug: "stablecoins",
    color: "bg-emerald-500",
    icon: <FaRegMoneyBillAlt size="50" className="text-white" />,
  },
  {
    title: "Smart Contract",
    content:
      "Kryptowährungen, welche die Entwicklung von Anwendungen ermöglichen.",
    slug: "smart-contracts",
    color: "bg-slate-500",
    icon: <AiOutlineAppstoreAdd size="50" className="text-white" />,
  },
  {
    title: "NFTs",
    content: "Kryptowährungen, welche NFTs sind.",
    slug: "nft",
    color: "bg-lime-500",
    icon: <AiFillCrown size="50" className="text-white" />,
  },
  {
    title: "Zentrale Börsen (CEX)",
    content:
      "Börsen, die einen eigene Kryptowährungen haben, gehören zu dieser Kategorie.",
    slug: "cex",
    color: "bg-blue-600",
    icon: <AiOutlineBank size="50" className="text-white" />,
  },
  {
    title: "Dezentrale Börsen (DEX)",
    content:
      "Kryptowährungen von dezentralen Börsen, gehören zu dieser Kategorie.",
    slug: "dex",
    color: "bg-fuchsia-500",
    icon: <AiOutlineCodeSandbox size="50" className="text-white" />,
  },
  {
    title: "Gaming",
    content: "Spiele die eine eigene Kryptowährung haben.",
    slug: "gaming",
    color: "bg-rose-600",
    icon: <IoGameControllerOutline size="50" className="text-white" />,
  },
  {
    title: "Meme Coins",
    content: "Kryptowährungen die aus Spaß entstanden sind.",
    slug: "meme-coins",
    color: "bg-amber-400",
    icon: <AiOutlineReddit size="50" className="text-white" />,
  },
];
