import React from "react";
import Layout from "@components/layout";
import SEO from "@components/seo";
import Link from "@components/UI-Elements/Navigation/Link";
import Title from "@components/UI-Elements/Typography/Title";
import { Url } from "@common/config/url-builder";
import CategoryCard from "@components/UI-Elements/Cards/CategoryCard";
import { categoriesCards } from "@common/data/content/category-cards";

const title = "Kryptowährungen nach Kategorien";
const Page: React.FC = () => {
  return (
    <Layout prose>
      <SEO keywords={["Kryptowährungen", "kategorien"]} title={title} />
      <div className="pb-10 text-center">
        <Title>{title}</Title>
        <p>
          Kryptowährungen lassen sich in verschiedene Kategorien einteilen. Im
          gegensatz zu{" "}
          <Link
            href="https://www.blockchaincenter.net/klassifizierung-von-kryptowaehrungen/"
            external
          >
            anderen Ansätzen
          </Link>
          , teile ich diese nach Anwendung bzw. Zielsetzung ein. Diese
          Kategorien sind trotzdem nicht immer eindeutig, daher können manche
          Kryptowährungen in mehrere Kategorien vorkommen.
        </p>
      </div>
      <div className="not-prose grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-3">
        {categoriesCards.map(({ slug, ...others }) => (
          <CategoryCard
            key={slug}
            linkProps={{ to: Url.knowledge.category(slug) }}
            {...others}
          />
        ))}
      </div>
    </Layout>
  );
};

export default Page;
