import React from "react";
import Button from "../Button";
import Link, { LinkProps } from "../Navigation/Link";

export type CategoryCardProps = {
  title?: string;
  linkProps?: Omit<LinkProps, "type">;
  icon?: React.ReactNode;
  color?: string;
  content?: React.ReactNode;
};

const CategoryCard: React.FC<CategoryCardProps> = ({
  title,
  linkProps,
  content,
  icon,
  color,
}) => {
  return (
    <div className="rounded-lg border border-gray-200 bg-white shadow-md dark:border-gray-700 dark:bg-gray-800">
      <Link {...linkProps}>
        <div
          className={`rounded-t-lg ${color} flex h-24 items-center justify-center`}
        >
          {icon}
        </div>
      </Link>
      <div className="p-5">
        <Link {...linkProps} type="hover">
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            {title}
          </h5>
        </Link>
        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
          {content}
        </p>
        <Button {...linkProps} arrow>
          Mehr
        </Button>
      </div>
    </div>
  );
};

export default CategoryCard;
