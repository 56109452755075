import React from "react";
import Link, { LinkProps } from "./Navigation/Link";
import { FiExternalLink } from "@react-icons/all-files/fi/FiExternalLink";
import Arrow from "./Icons/Arrow";
import clsx from "clsx";

const colorStyles = {
  blue: {
    base: "text-white bg-blue-700 hover:bg-blue-800 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800",
    outline: "border text-blue-700 hover:text-white border-blue-700 hover:bg-blue-800 focus:ring-blue-300 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-600 dark:focus:ring-blue-800"
  },
  default: {
    base: "border text-gray-900 bg-white border-gray-300 hover:bg-gray-100 focus:ring-gray-200 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700",
    outline: "border text-gray-900 border-gray-300 hover:bg-gray-100 focus:ring-gray-200 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700",
  },
  dark: {
    base: "text-white bg-gray-800 hover:bg-gray-900 focus:ring-gray-300 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700",
    outline: "text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-gray-300 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800"
  },
  green: {
    base: "text-white bg-green-700 hover:bg-green-800 focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800",
    outline: "text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-green-300 dark:border-green-500 dark:text-green-500 dark:hover:text-white dark:hover:bg-green-600 dark:focus:ring-green-800"
  },
  red: {
    base: "text-white bg-red-700 hover:bg-red-800 focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900",
    outline: "text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-red-300 dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900"
  },
  yellow: {
    base: "text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-yellow-300 dark:focus:ring-yellow-900",
    outline: "text-yellow-400 hover:text-white border border-yellow-400 hover:bg-yellow-500 focus:ring-yellow-300 dark:border-yellow-300 dark:text-yellow-300 dark:hover:text-white dark:hover:bg-yellow-400 dark:focus:ring-yellow-900"
  },
  purple: {
    base: "text-white bg-purple-700 hover:bg-purple-800 focus:ring-purple-300 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900",
    outline: "text-purple-700 hover:text-white border border-purple-700 hover:bg-purple-800 focus:ring-purple-300 dark:border-purple-400 dark:text-purple-400 dark:hover:text-white dark:hover:bg-purple-500 dark:focus:ring-purple-900"
  }
}

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  arrow?: boolean;
  className?: string;
  color?: keyof typeof colorStyles;
  variant?: 'base' | 'outline';
} & Omit<LinkProps, "type">;

const Button: React.FC<Props> = ({ children, ...others }) => {
  const { href, external, to, className, variant, arrow = false, ...otherProps } = others;
  const buttonStyle = clsx(
    "inline-flex items-center py-2 px-3 text-sm font-medium text-center rounded-lg focus:ring-2 focus:outline-none",
    colorStyles[others.color || "blue"][variant || 'base'] as string,
  )

  if (href || to) {
    return (
      <Link href={href} to={to} external={external} className={className} type="none">
        <button className={buttonStyle} {...otherProps}>
          {children} {arrow && <Arrow />} {external && <FiExternalLink className="ml-1"/>}
        </button>
      </Link>
    );
  } else {
    return (
      <button className={`${buttonStyle} ${className}`} {...otherProps}>
        {children} {arrow && <Arrow />}
      </button>
    );
  }
};

export default Button;
